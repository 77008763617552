import "./App.css";
import Header from "./components/Header/HeaderSection";
import FooterSection from "./components/Header/Footer/FooterSection";
import ContactForm from "./components/Header/ContactPage/ContactForm";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Home from "./Home";
import Itineary from "./components/Header/Itineary/Itineary";
import TravelBuddySection from "./components/Header/TravelBuddy/TravelBuddySection";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import UserProfile from "./components/Header/Profile/UserProfile";
import LoginWithNumber from "./Auth/LoginWithNumber";
import AboutPage from "./components/Header/Aboutpage/AboutPage";
import CarBookingList from "./components/Header/CarParking/CarBookingList";
import BlogList from "./components/Header/Blogs/BlogList";
import GovardhanParikrama from "./components/Header/Parikrama/GovardhanParikrama";
import VrindavanParikrama from "./components/Header/Parikrama/VrindavanParikrama";
import HotelList from "./components/Header/Hotels/HotelList";

// Router setup
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainComponent />,
    children: [
      { path: "/", element: <Home /> },
      { path: "hotelList", element: <HotelList /> },
      { path: "contact-form", element: <ContactForm /> },
      { path: "about-page", element: <AboutPage /> },
      { path: "itineary", element: <Itineary /> },
      { path: "travel-buddy", element: <TravelBuddySection /> },
      { path: "login", element: <Login /> },
      { path: "loginWithOtp", element: <LoginWithNumber /> },
      { path: "profile", element: <UserProfile /> },
      { path: "carbookinglist", element: <CarBookingList /> },
      { path: "blogs", element: <BlogList /> },
      { path: "govardhan-parikrama", element: <GovardhanParikrama /> },
      { path: "vrindavan-parikrama", element: <VrindavanParikrama /> },
    ],
  },
]);

// Main component with header, outlet for routing, and footer
function MainComponent() {
  // Function to open WhatsApp chat with a predefined message
  const openWhatsApp = () => {
    const message = encodeURIComponent("How can I help you?");
    const url = `https://wa.me/6284041058?text=${message}`;
    window.open(url, "_blank");
  };

  return (
    <div className="font-sans antialiased text-gray-900">
      <Header />
      <div>
        <Outlet />
      </div>
      <FooterSection />

      {/* WhatsApp floating button */}
      <button
        onClick={openWhatsApp}
        className="fixed bottom-4 right-4 bg-violet-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300 z-50"
        aria-label="WhatsApp Chat"
      >
        {/* WhatsApp icon */}
        <i className="fa fa-whatsapp text-4xl text-white"></i>
      </button>

      {/* Pop-up message */}
      <div className="fixed bottom-20 right-4 bg-gray-800 text-white p-3 rounded shadow-lg transition duration-300">
        How can I help you?
      </div>
    </div>
  );
}

// App component with RouterProvider
function App() {
  return <RouterProvider router={router} />;
}

export default App;
