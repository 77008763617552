import React, { useState } from 'react';

const GovardhanParikrama = () => {
  const [selectedTransport, setSelectedTransport] = useState('');
  const [bookingSubmitted, setBookingSubmitted] = useState(false);

  const itinerary = [
    {
      day: 'Day 1: Morning',
      title: 'Govardhan Arrival & Introduction',
      description:
        'Arrive at Govardhan. Spend the day exploring the spiritual significance of Govardhan Hill, and begin your journey with a visit to the famous Radha Kund and Shyam Kund.',
      activities: ['Radha Kund', 'Shyam Kund', 'Manasi Ganga'],
      image: 'https://i.ibb.co/WtCZSKN/kusumsarovar.jpg', // Replace with actual image path
    },
    {
      day: 'Day 1: Afternoon',
      title: 'Govardhan Parikrama - East Side',
      description:
        'Start your Parikrama early morning, covering the eastern part of Govardhan Hill. Visit Kusum Sarovar, the sacred lake where Radha would collect flowers, and continue to Manasi Ganga.',
      activities: ['Kusum Sarovar', 'Govardhan Temple', 'Pooja at Manasi Ganga'],
      image: 'https://i.ibb.co/C8qw2SG/parikrama.jpg', // Replace with actual image path
    },
    {
      day: 'Day 1: Evening',
      title: 'Govardhan Parikrama - West Side',
      description:
        'Continue the Parikrama covering the western side of the Govardhan Hill. Visit important spiritual sites such as the Daan Ghati Temple and Poonchari ka Lautha.',
      activities: ['Daan Ghati Temple', 'Poonchari Ka Lautha', 'Govinda Kund'],
      image: 'https://i.ibb.co/820xz7T/gaumata.jpg', // Replace with actual image path
    },
  ];

  // Handle mode of transport selection/deselection
  const handleTransportChange = (mode) => {
    if (selectedTransport === mode) {
      setSelectedTransport(''); // Unselect the transport if already selected
    } else {
      setSelectedTransport(mode); // Select the transport
    }
  };

  const handleBookingSubmit = () => {
    if (selectedTransport) {
      setBookingSubmitted(true);
      alert(`Booking successful! Mode of transport selected: ${selectedTransport}`);
    } else {
      alert('Please select a mode of transport before booking.');
    }
  };

  return (
    <div className="bg-gray-50 py-10">
      <div className="container mx-auto px-4">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-blue-900">Govardhan Parikrama Itinerary</h1>
          <p className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto">
            Join the sacred Parikrama around Govardhan Hill, where devotees walk around the hill in reverence of Lord Krishna. Explore the spiritual landmarks over the course of a day.
          </p>
        </div>

        {/* Itinerary Section */}
        <div className="space-y-12">
          {itinerary.map((item, index) => (
            <div
              key={index}
              className="bg-white border border-gray-300 shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row"
            >
              {/* Image */}
              <div className="lg:w-1/4 border-b lg:border-b-0 lg:border-r border-gray-300">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-48 lg:h-full object-cover"
                />
              </div>

              {/* Itinerary Content */}
              <div className="lg:w-3/4 p-6 flex flex-col justify-between">
                <div>
                  <h2 className="text-2xl font-semibold text-blue-900 mb-4">
                    {item.day}: {item.title}
                  </h2>
                  <p className="text-gray-700 text-base mb-4">{item.description}</p>

                  <ul className="space-y-2">
                    {item.activities.map((activity, i) => (
                      <li key={i} className="flex items-center space-x-2">
                        <svg
                          className="w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-11h2v5h-2V7zm0 6h2v2h-2v-2z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="text-gray-600">{activity}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Transport Selection Section */}
        <section className="my-12">
          <h2 className="text-3xl font-semibold text-blue-800 mb-6 text-center">Select Your Mode of Transport</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 text-center">
            {['E-rickshaw', 'Car', 'Auto', 'Bike'].map((mode) => (
              <div
                key={mode}
                onClick={() => handleTransportChange(mode)}
                className={`p-6 cursor-pointer rounded-lg border-2 transition-all duration-300 ${
                  selectedTransport === mode ? 'border-blue-700 bg-blue-100' : 'border-gray-300 bg-white'
                } hover:bg-blue-50 hover:shadow-lg`}
              >
                <div className="text-4xl mb-2">
                  {mode === 'E-rickshaw' ? '🛺' : mode === 'Car' ? '🚗' : mode === 'Auto' ? '🚖' : '🏍️'}
                </div>
                <p className="font-semibold text-lg text-gray-800">{mode}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Book Now Section */}
        <div className="text-center mt-16">
          <button
            className="px-8 py-3 bg-blue-700 text-white font-bold rounded-full hover:bg-blue-800 transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
            onClick={handleBookingSubmit}
          >
            Book Now
          </button>
          {bookingSubmitted && (
            <p className="mt-6 text-green-600 font-medium">Thank you for booking! We have received your request.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GovardhanParikrama;
