import React, { useState } from 'react';
import Modal from '../Common/Modal';
import BookingPage from '../Booking/BookingPage';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ image, title, description, price, days,person }) => {
  const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedDescription = description.split(' ').slice(0, 10).join(' ') + '...';
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRedirect = () => {
    navigate('/itineary');
  };
  
  return (
    <>
      <div
        onClick={handleRedirect}
        className="max-w-sm rounded-lg overflow-hidden shadow-lg transform transition-transform duration-200 hover:scale-105 m-4 bg-white"
      >
        <div className="relative">
          <img className="w-full h-48 md:h-40 object-cover" src={image} alt={title} />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-70 hover:opacity-90 transition-opacity duration-300 flex items-center justify-center"></div>
        </div>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 text-gray-900 text-lg md:text-xl">{title}</div>
          {/* <p className="text-gray-700 text-base bg-gray-100 p-2 rounded-lg text-sm md:text-base">
            {description}
          </p> */}
          <p className="text-gray-700 mt-2">
                {isExpanded ? description : truncatedDescription}
                <button 
                    onClick={handleToggle}
                    className="text-blue-500 ml-2"
                >
                    {isExpanded ? 'Read Less' : 'Read More'}
                </button>
                </p>
          <div className="text-gray-500 text-sm mt-2">Duration: {days}</div>
          <div className="mt-4 flex justify-between items-center">
            <span className="text-xl font-bold text-gray-900 text-lg md:text-xl">Rs. {price}
              {/* <span>{person}</span> */}
              </span>
            <button
              className="bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 shadow-md"
              onClick={handleOpenModal}
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <BookingPage onClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default ProductCard;
