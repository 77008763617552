import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/authContexts";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import BookingPage from "./Booking/BookingPage";
import Modal from "./Common/Modal";
// Import the logo image
import logo from "../../Assests/Images/headerlogo/logo.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsSuccessModalOpen(true); // Show success modal
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        navigate("/"); // Redirect to homepage after showing the modal
      }, 2000); // Modal visible for 2 seconds
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
    navigate("/"); // Redirect to homepage if modal is manually closed
  };

  return (
    <>
      <header className="bg-gradient-to-r from-green-500 to-blue-700 text-white py-4 shadow-lg">
        <div className="container mx-auto flex justify-between items-center px-6">
          {/* Updated Link component with logo and text */}
          <Link to="/" className="flex items-center">
            {/* Logo image */}
            <img src={logo} alt="Krishna Divine Tour Logo" className="h-12 mr-2" />
            <span className="text-xl md:text-2xl font-semibold tracking-wide">
              Krishna Divine Tour
            </span>
          </Link>
          <nav className="hidden md:flex space-x-6">
            <Link to="hotelList" className="text-lg font-medium hover:underline transition duration-300">
              Hotels
            </Link>
            <Link to="carbookinglist" className="text-lg font-medium hover:underline transition duration-300">
              Cab/Bike
            </Link>
            <Link to="blogs" className="text-lg font-medium hover:underline transition duration-300">
              Blogs
            </Link>
            <Link to="about-page" className="text-lg font-medium hover:underline transition duration-300">
              About Us
            </Link>
            <div className="relative group">
              <Link to="#" className="text-lg font-medium hover:underline transition duration-300">
                Parikrama
              </Link>
              <div className="absolute left-0 mt-2 hidden group-hover:block bg-white shadow-md p-2 z-10 rounded-lg">
                <Link
                  to="brij-parikrma"
                  className="block px-4 py-2 text-sm font-medium text-black hover:text-blue-500 hover:bg-gray-100 transition duration-300"
                >
                  Brij Parikrama
                </Link>
                <Link
                  to="govardhan-parikrama"
                  className="block px-4 py-2 text-sm font-medium text-black hover:text-blue-500 hover:bg-gray-100 transition duration-300"
                >
                  Govardhan Parikrama
                </Link>
                <Link
                  to="vrindavan-parikrama"
                  className="block px-4 py-2 text-sm font-medium text-black hover:text-blue-500 hover:bg-gray-100 transition duration-300"
                >
                  Vrindavan Parikrama
                </Link>
              </div>
            </div>

            {currentUser ? (
              <button onClick={handleLogout} className="text-lg font-medium hover:underline transition duration-300">
                Logout
              </button>
            ) : (
              <Link to="login" className="text-lg font-medium hover:underline transition duration-300">
                Login
              </Link>
            )}
          </nav>
          <button onClick={handleOpenModal} className="hidden md:block bg-yellow-300 text-blue-900 py-2 px-4 rounded-full shadow-md hover:bg-yellow-400 transition duration-300">
            Book Now
          </button>
          <button className="md:hidden flex items-center" onClick={toggleMenu}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        {isMenuOpen && (
          <nav className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="hotelList" className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-500">
                Hotels
              </Link>
              <Link to="carbookinglist" className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-500">
                Cab/Bike
              </Link>
              <Link to="blogs" className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-500">
                Blogs
              </Link>
              <Link to="about-page" className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-500">
                About Us
              </Link>
              {currentUser ? (
                <button
                  onClick={handleLogout}
                  className="block w-full px-3 py-2 rounded-md text-base font-medium text-white bg-red-500 hover:bg-red-600"
                >
                  Logout
                </button>
              ) : (
                <Link to="login" className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-500">
                  Login
                </Link>
              )}
              <button onClick={handleOpenModal} className="w-full bg-yellow-300 text-blue-900 py-2 px-4 rounded-full shadow-md hover:bg-yellow-400 transition duration-300">
                Book Now
              </button>
            </div>
          </nav>
        )}
      </header>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <BookingPage onClose={handleCloseModal} />
      </Modal>
      <Modal isOpen={isSuccessModalOpen} onClose={handleSuccessModalClose}>
        <div className="p-6 text-center">
          <h2 className="text-xl font-semibold mb-4">Success</h2>
          <p>You have successfully logged out.</p>
        </div>
      </Modal>
    </>
  );
};

export default Header;
