import React from 'react';
import HotelCard from './HotelCard';

const HotelList = () => {
  const hotels = [
    {
      id: 1,
      name: 'Luxury Hotel',
      description: 'Enjoy luxury living with top-notch services and amenities.',
      price: 350,
      image: 'https://via.placeholder.com/400x300',
    },
    {
      id: 2,
      name: 'Beach Resort',
      description: 'Experience a beautiful seaside stay at our exclusive resort.',
      price: 420,
      image: 'https://via.placeholder.com/400x300',
    },
    {
      id: 3,
      name: 'Mountain Retreat',
      description: 'A peaceful escape into the mountains, ideal for nature lovers.',
      price: 290,
      image: 'https://via.placeholder.com/400x300',
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-semibold text-center mb-8 text-gray-800">
        Featured Hotels
      </h2>
      <div className="flex flex-wrap justify-center">
        {hotels.map(hotel => (
          <HotelCard key={hotel.id} hotel={hotel} />
        ))}
      </div>
    </div>
  );
};

export default HotelList;
