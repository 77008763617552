import React from 'react';

const HotelCard = ({ hotel }) => {
  return (
    <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 m-4">
      <div className="relative">
        <img
          className="w-full h-56 object-cover"
          src={hotel.image}
          alt={hotel.name}
        />
        <div className="absolute top-0 right-0 mt-2 mr-2">
          <button className="bg-white rounded-full p-2 shadow-md">
            <svg
              className="h-6 w-6 text-red-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
            </svg>
          </button>
        </div>
      </div>
      <div className="p-6">
        <h3 className="font-semibold text-xl text-gray-800">{hotel.name}</h3>
        <p className="mt-2 text-gray-600">{hotel.description}</p>
        <div className="mt-4 flex justify-between items-center">
          <div>
            <span className="text-xl font-bold text-gray-900">${hotel.price}</span>
            <span className="text-sm text-gray-500"> / night</span>
          </div>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelCard;
