import React, { useState } from 'react';

const VrindavanParikrama = () => {
  const [selectedTransport, setSelectedTransport] = useState('');
  const [bookingSubmitted, setBookingSubmitted] = useState(false);

  const itinerary = [
    {
      day: 'Day 1',
      activities: [
        'Morning Aarti at Banke Bihari Temple',
        'Visit to ISKCON Temple',
        'Lunch at a local restaurant',
        'Evening visit to Radha Raman Temple',
        'Dinner and Rest',
      ],
    },
    {
      day: 'Day 2',
      activities: [
        'Start the Parikrama early morning',
        'Visit Govind Dev Ji Temple',
        'Breakfast along the Parikrama route',
        'Stop at Keshi Ghat for meditation and reflection',
        'Evening Satsang at Prem Mandir',
      ],
    },
  ];

  const attractions = [
    {
      name: 'Banke Bihari Temple',
      description: 'A sacred temple dedicated to Lord Krishna, known for its vibrant and spiritually uplifting atmosphere.',
      image: 'https://example.com/banke_bihari.jpg', // Replace with actual image URL
    },
    {
      name: 'ISKCON Temple',
      description: 'A renowned Krishna temple offering a peaceful and devotional ambiance.',
      image: 'https://example.com/iskcon.jpg', // Replace with actual image URL
    },
    {
      name: 'Radha Raman Temple',
      description: 'One of the most important and ancient temples in Vrindavan, known for its beautiful deity of Radha Raman.',
      image: 'https://example.com/radha_raman.jpg', // Replace with actual image URL
    },
    {
      name: 'Prem Mandir',
      description: 'A beautiful temple illuminated at night, showcasing the love stories of Radha and Krishna.',
      image: 'https://example.com/prem_mandir.jpg', // Replace with actual image URL
    },
  ];

  const travelTips = [
    'Best time to visit is during the winter months (October to March).',
    'Wear comfortable walking shoes for the Parikrama.',
    'Respect local customs and traditions when visiting temples.',
    'Carry water and light snacks during the Parikrama.',
  ];

  const handleTransportChange = (mode) => {
    setSelectedTransport(mode);
  };

  const handleBookingSubmit = () => {
    if (selectedTransport) {
      setBookingSubmitted(true);
      alert(`Booking successful! Mode of transport selected: ${selectedTransport}`);
    } else {
      alert('Please select a mode of transport before booking.');
    }
  };

  return (
    <div className="bg-gray-50 p-8 font-sans text-gray-700">
      {/* Introduction Section */}
      <section className="text-center mb-12">
        <h1 className="text-4xl font-extrabold text-blue-900">Vrindavan Parikrama Itinerary</h1>
        <p className="text-lg mt-6 text-gray-600">
          Discover the divine beauty of Vrindavan as you walk through its sacred paths, explore holy temples, and immerse yourself in the spiritual energy of this ancient city.
        </p>
      </section>

      {/* Daily Itinerary Section */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold text-blue-800 mb-6">Daily Schedule</h2>
        {itinerary.map((dayPlan, index) => (
          <div key={index} className="mb-8 border-b pb-6">
            <h3 className="text-2xl font-bold text-blue-700 mb-4">{dayPlan.day}</h3>
            <ul className="list-disc ml-6 text-lg">
              {dayPlan.activities.map((activity, idx) => (
                <li key={idx} className="text-gray-600">{activity}</li>
              ))}
            </ul>
          </div>
        ))}
      </section>

      {/* Attractions Section */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold text-blue-800 mb-6">Main Attractions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {attractions.map((attraction, index) => (
            <div key={index} className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow flex flex-col md:flex-row items-center">
              {/* Attraction Image */}
              <img
                src={attraction.image}
                alt={attraction.name}
                className="w-1/3 md:w-1/4 h-32 md:h-48 rounded-lg object-cover mb-4 md:mb-0 md:mr-6"
              />
              {/* Attraction Details */}
              <div>
                <h3 className="text-xl font-bold text-blue-700">{attraction.name}</h3>
                <p className="text-md text-gray-600 mt-2">{attraction.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Travel Tips Section */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold text-blue-800 mb-6">Travel Tips</h2>
        <ul className="list-disc ml-6 text-lg">
          {travelTips.map((tip, index) => (
            <li key={index} className="mb-2 text-gray-600">{tip}</li>
          ))}
        </ul>
      </section>

      {/* Transport Selection Section */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold text-blue-800 mb-6">Select Your Mode of Transport</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {['E-rickshaw', 'Car', 'Auto-Rickshaw', 'Bike'].map((mode) => (
            <div
              key={mode}
              onClick={() => handleTransportChange(mode)}
              className={`p-6 text-center cursor-pointer rounded-lg border-2 transition-all duration-300 ${
                selectedTransport === mode ? 'border-blue-700 bg-blue-100' : 'border-gray-300 bg-white'
              } hover:bg-blue-50 hover:shadow-lg`}
            >
              <div className="text-4xl mb-2">
                {mode === 'E-rickshaw' ? '🛺' : mode === 'Car' ? '🚗' : mode === 'Auto-Rickshaw' ? '🚖' : '🏍️'}
              </div>
              <p className="font-semibold text-lg text-gray-800">{mode}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Book Now Section */}
      <section className="text-center mt-16">
        <button
          className="px-8 py-3 bg-blue-700 text-white font-bold rounded-full hover:bg-blue-800 transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
          onClick={handleBookingSubmit}
        >
          Book Now
        </button>
        {bookingSubmitted && (
          <p className="mt-6 text-green-600 font-medium">Thank you for booking! We have received your request.</p>
        )}
      </section>
    </div>
  );
};

export default VrindavanParikrama;
