import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Context/authContexts';
import Spinner from '../Common/Spinner'; // Replace './Spinner' with the correct path to your spinner component

const UserProfile = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  // Initial state for all profile fields
  const [profileInfo, setProfileInfo] = useState({
    name: '',
    email: '',
    birthday: '',
    gender: '',
    maritalStatus: '',
    address: '',
    pincode: '',
    state: 'Delhi',
  });

  useEffect(() => {
    if (currentUser) {
      // Mock data populated from `currentUser` or you can fetch it from the backend
      setUserData({
        name: currentUser.displayName || 'User',
        email: currentUser.email || 'Not provided',
        birthday: '01/01/1990',
        gender: 'Male',
        maritalStatus: 'Single',
        address: '123 Street, City',
        pincode: '110001',
        state: 'Delhi',
        profilePicture: currentUser.photoURL || 'default-profile-pic.jpg',
      });
      // Initialize profileInfo state with user data
      setProfileInfo({
        name: currentUser.displayName || '',
        email: currentUser.email || '',
        birthday: '01/01/1990',
        gender: 'Male',
        maritalStatus: 'Single',
        address: '123 Street, City',
        pincode: '110001',
        state: 'Delhi',
      });
    }
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleUpdateProfile = () => {
    // Here you would make an API call to update the profile in the backend.
    setUserData(profileInfo);
    setEditMode(false);
  };

  if (!userData) {
    return <Spinner />; // Replace 'Spinner' with your actual spinner component
  }

  return (
    <div className="flex max-w-7xl mx-auto mt-10">
      {/* Sidebar */}
      <div className="w-1/4 bg-white shadow-lg rounded-lg p-4">
        <div className="text-center">
          <img
            className="w-24 h-24 mx-auto rounded-full"
            src={userData.profilePicture}
            alt="Profile"
          />
          <h2 className="text-lg font-semibold mt-4">PERSONAL PROFILE</h2>
        </div>

        <nav className="mt-6 space-y-2">
          <button className="w-full text-left p-2 text-blue-600 bg-gray-50 rounded-lg hover:bg-blue-100">
            Profile
          </button>
          <button className="w-full text-left p-2 text-gray-600 hover:bg-gray-100 rounded-lg">
            Login Details
          </button>
          <button className="w-full text-left p-2 text-gray-600 hover:bg-gray-100 rounded-lg">
            Co-Travellers
          </button>
          <button className="w-full text-left p-2 text-gray-600 hover:bg-gray-100 rounded-lg">
            Logged In Devices
          </button>
          <button className="w-full text-left p-2 text-red-600 hover:bg-red-100 rounded-lg">
            Logout
          </button>
        </nav>
      </div>

      {/* Main Content */}
      <div className="w-3/4 p-6">
        {/* Profile Information Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mt-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Profile</h2>
            {!editMode && (
              <button
                className="text-blue-600 font-semibold"
                onClick={() => setEditMode(true)}
              >
                <svg
                  className="inline-block w-5 h-5 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                EDIT
              </button>
            )}
          </div>

          <p className="text-gray-500 text-sm mb-6">
            Basic info, for a faster booking experience
          </p>

          {/* Profile Fields */}
          <div className="grid grid-cols-1 gap-4 text-sm">
            {editMode ? (
              <>
                <div className="flex justify-between">
                  <span>NAME</span>
                  <input
                    type="text"
                    name="name"
                    value={profileInfo.name}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  />
                </div>
                <div className="flex justify-between">
                  <span>EMAIL</span>
                  <input
                    type="email"
                    name="email"
                    value={profileInfo.email}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  />
                </div>
                <div className="flex justify-between">
                  <span>BIRTHDAY</span>
                  <input
                    type="date"
                    name="birthday"
                    value={profileInfo.birthday}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  />
                </div>
                <div className="flex justify-between">
                  <span>GENDER</span>
                  <select
                    name="gender"
                    value={profileInfo.gender}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="flex justify-between">
                  <span>MARITAL STATUS</span>
                  <select
                    name="maritalStatus"
                    value={profileInfo.maritalStatus}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="flex justify-between">
                  <span>YOUR ADDRESS</span>
                  <input
                    type="text"
                    name="address"
                    value={profileInfo.address}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  />
                </div>
                <div className="flex justify-between">
                  <span>PINCODE</span>
                  <input
                    type="text"
                    name="pincode"
                    value={profileInfo.pincode}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  />
                </div>
                <div className="flex justify-between">
                  <span>STATE</span>
                  <input
                    type="text"
                    name="state"
                    value={profileInfo.state}
                    onChange={handleInputChange}
                    className="border p-1 rounded"
                  />
                </div>
                <button
                  className="mt-4 bg-blue-600 text-white px-4 py-2 rounded"
                  onClick={handleUpdateProfile}
                >
                  Save Changes
                </button>
              </>
            ) : (
              <>
                <div className="flex justify-between">
                  <span>NAME</span>
                  <span>{userData.name}</span>
                </div>
                <div className="flex justify-between">
                  <span>EMAIL</span>
                  <span>{userData.email}</span>
                </div>
                <div className="flex justify-between">
                  <span>BIRTHDAY</span>
                  <span>{userData.birthday}</span>
                </div>
                <div className="flex justify-between">
                  <span>GENDER</span>
                  <span>{userData.gender}</span>
                </div>
                <div className="flex justify-between">
                  <span>MARITAL STATUS</span>
                  <span>{userData.maritalStatus}</span>
                </div>
                <div className="flex justify-between">
                  <span>YOUR ADDRESS</span>
                  <span>{userData.address}</span>
                </div>
                <div className="flex justify-between">
                  <span>PINCODE</span>
                  <span>{userData.pincode}</span>
                </div>
                <div className="flex justify-between">
                  <span>STATE</span>
                  <span>{userData.state}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
